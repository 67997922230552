class WorkerPool {
    private readonly size: number
    private queue: (() => void)[] = []
    private workers: Set<Promise<void>> = new Set()

    constructor(size: number) {
        this.size = size
    }

    async add(task: () => Promise<any>): Promise<void> {
        if (this.workers.size >= this.size) {
            await this._waitForWorker()
        }

        this._runTask(task)
    }

    private _runTask(task: () => Promise<any>): void {
        const worker = task().finally(() => {
            this.workers.delete(worker)
            this._processQueue()
        })

        this.workers.add(worker)
    }

    private _waitForWorker(): Promise<void> {
        return new Promise((resolve) => {
            this.queue.push(resolve)
        })
    }

    private _processQueue(): void {
        if (this.queue.length > 0 && this.workers.size < this.size) {
            const next = this.queue.shift()
            if (next) {
                next()
            }
        }
    }

    async waitForAllWorkers(): Promise<void> {
        await Promise.all([...this.workers])
    }
}

export {WorkerPool}