import {iconPreviewExtensions, MediaStorageType} from "./interface"
import {WorkerPool} from "@utils/WorkerPool"

export const getPreviewIcon = (extension: string): string => iconPreviewExtensions.includes(extension) ? `/extensions/${ extension }.svg` : `/extensions/unknown.svg`

export const isImage = (extension: string): boolean => ["png", "jpeg", "jpg", "webp", "gif", "bmp"].includes(extension)
export const hasBrowserNativePreview = (extension: string): boolean => [
    "pdf",
    "jpg",
    "jpeg",
    "mp3",
    "png",
    "webm",
    "webp",
    "mp4",
    "vp9",
    "txt",
    "xml",
    "json",
    "wav",
    "svg",
    "gif",
].includes(extension)

export const getImagePreview = (image: File, extension: string): Promise<string> => {
    if (isImage(extension)) {
        const reader = new FileReader()
        return new Promise(resolve => {
            reader.onload = async (e) => {
                const blob = new Blob([e.target?.result as ArrayBuffer], {
                    type: image.type
                })
                resolve(URL.createObjectURL(blob))
            }
            reader.readAsArrayBuffer(image)
        })
    }

    return new Promise(resolve => resolve(getPreviewIcon(extension)))
}

export const pickFileDialog = (multiple: boolean = false): Promise<File[]> => {
    return new Promise((resolve, reject) => {
        const fakeInput = document.createElement("input")

        fakeInput.classList.add("hidden")
        fakeInput.type = "file"
        fakeInput.multiple = multiple

        let filesPicked = false

        fakeInput.onchange = (): void => {
            filesPicked = true
            // @ts-ignore - spread is valid
            resolve([...fakeInput.files])
        }

        const handleFocus = (): void => {
            setTimeout(() => !filesPicked && reject(), 150)
            window.removeEventListener("focus", handleFocus)
        }

        window.addEventListener("focus", handleFocus)

        fakeInput.click()
    })
}

export interface UploadStatus
{
    id: string | undefined
    status: "compressing" | "initializing" | "uploading" | "uploaded" | null
    size: number
    chunk: {
        total: number
        current: number
    },
    previewUrl?: string | null
    percentage?: number
    cancelled?: boolean
}

export type UploadConfig = {
    file: File
    extension: string
    onProgress: (status: UploadStatus) => void | boolean
    storageType: MediaStorageType
    id: undefined | string
    position: number
}

export const uploadFile = async (config: Partial<UploadConfig>, dispatchEvent: (name: string, data: any) => any) => {
    let {
        file,
        onProgress,
        extension,
        storageType,
        id,
        position,
    } = config

    if (file === undefined) {
        throw new Error("`file` is required for upload")
    }

    if (id === undefined) {
        id = ""
    }

    if (storageType === undefined) {
        storageType = MediaStorageType.PRIVATE
    }

    if (position === undefined) {
        position = 0
    }

    const chunkSize = 1024 * 1024 * 2 // ~2MB
    const totalChunks = Math.ceil(file.size / chunkSize)

    let stop = onProgress && onProgress({
        id: undefined,
        status: "initializing",
        size: file.size,
        chunk: {
            total: totalChunks,
            current: 0,
        },
    })
    if (stop) {
        return
    }

    // @ts-ignore
    let url = window._routes.media.tempId + "/" + storageType
    if (id !== "") {
        url += "/" + id
    }

    id = await fetch(url).then(r => r.json()).then(r => r.id)

    stop = onProgress && onProgress({
        id: id,
        status: "initializing",
        size: file.size,
        chunk: {
            total: totalChunks,
            current: 0,
        },
    })
    if (stop) {
        return
    }

    const reader = (file.stream() as unknown as ReadableStream<Uint8Array>).getReader()

    let buffer: Uint8Array

    const readableWithDefinedChunks = new ReadableStream({
        async pull(controller)
        {
            let fulfilledChunkQuota = false

            while (!fulfilledChunkQuota) {
                const status = await reader.read()

                if (!status.done) {
                    const chunk = status.value
                    buffer = new Uint8Array([...(buffer || []), ...chunk])

                    while (buffer?.byteLength >= chunkSize) {
                        const chunkToSend = buffer.slice(0, chunkSize)
                        controller.enqueue(chunkToSend)

                        buffer = new Uint8Array([...buffer.slice(chunkSize)])

                        fulfilledChunkQuota = true
                    }
                }

                if (status.done) {
                    fulfilledChunkQuota = true

                    if (buffer?.byteLength > 0) {
                        controller.enqueue(buffer)
                    }

                    controller.close()
                }
            }
        },
    })

    const streamReader = readableWithDefinedChunks.getReader()

    let pool = new WorkerPool(4)

    let index = 0
    while (true) {
        const { done, value } = await streamReader.read()

        if (done) {
            break
        }

        stop = onProgress && onProgress({
            id: id,
            status: "uploading",
            size: file.size,
            chunk: {
                total: totalChunks,
                current: index,
            }
        })

        if (stop) {
            reader.releaseLock()
            await streamReader.cancel()
            try {
                await readableWithDefinedChunks.cancel()
            } catch (e) {
            }
            break
        }

        // @ts-ignore
        const request = () => fetch(window._routes.media.chunk + "/" + storageType + "/" + id + "/" + index, {
            method: 'POST',
            body: new Blob([value]),
            cache: 'no-store',
            redirect: 'manual',
            referrerPolicy: 'same-origin',
            mode: 'same-origin'
        }).catch(() => {
            stop = true
        })

        await pool.add(request);

        index++
    }

    if(!stop) {
        await pool.waitForAllWorkers();
    }

    const response: {
        id: string
        size: number
        image?: {
            width: number
            height: number
        }
        // @ts-ignore
    } = await fetch(window._routes.media.stitch + "/" + id + "/" + position + "/" + extension).then(r => r.json())

    dispatchEvent("stitch", response)

    const lastStatus = {
        id: response.id,
        status: "uploaded",
        size: response.size,
        chunk: {
            total: totalChunks,
            current: totalChunks,
        }
    }

    onProgress && onProgress(lastStatus as UploadStatus)

    reader.releaseLock()
    await streamReader.cancel()
    try {
        await readableWithDefinedChunks.cancel()
    } catch (e) {
    }

    return lastStatus
}
