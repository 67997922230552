import {FormEntry} from "@shared/Form/interface"
import {UploadStatus} from "./utils"

export const iconPreviewExtensions = [
    '3ds',
    'aac',
    'ai',
    'avi',
    'bmp',
    'cad',
    'cdr',
    'css',
    'dat',
    'dll',
    'dmg',
    'doc',
    'docx',
    'eps',
    'fla',
    'flv',
    'gif',
    'html',
    'indd',
    'iso',
    'jpeg',
    'jpg',
    'js',
    'midi',
    'mov',
    'mp3',
    'mp4',
    'mpg',
    'pdf',
    'php',
    'png',
    'ppt',
    'ps',
    'psd',
    'raw',
    'sql',
    'svg',
    'tif',
    'txt',
    'wmv',
    'xls',
    'old',
    'xlsx',
    'xml',
    'csv',
    'zip',
]

export enum MediaStorageType
{
    PRIVATE = "private",
    PUBLIC = "public",
}

export type MediaUploadTypeExtra = {
    allowed_extensions: string[] | null
    cropper_js: boolean
    max_files: number
    min_files: number
    prototype_name: string
    hide_name_input: boolean
    storage_type: MediaStorageType
    slim: boolean
}

export interface MediaFormEntry extends FormEntry
{
    data: ExistingMediaData
    children: {
        id: FormEntry,
        position: FormEntry,
        name: FormEntry,
        description: FormEntry
    }
}

export interface MultiUploadEntry {
    uploadStatus?: UploadStatus
    existingMedia?: ExistingMediaData
    extension?: string
    file?: File
    id: any
    prototypeIndex: number
}

export interface MediaUploadCollectionFormEntry extends FormEntry
{
    extra: {
        prototype: MediaFormEntry
        counter: number
        lastKey: number
    }
    children: MediaFormEntry[]
}

export interface MediaUploadFormEntry extends FormEntry
{
    extra: MediaUploadTypeExtra
    children: {
        collection: MediaUploadCollectionFormEntry
    }
}

export interface ExistingMediaData {
    extension: string
    filename: string
    name: string
    url: string
    size: number
    id: string
}
